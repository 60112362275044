
export default function IDidNotReceiveAnEmailAfterPayingWhatNow()
{
    return (
        <>
            <div className="support-questions-header">I Did Not Receive an Email After Paying. What Now?</div>
            <article className="support-questions-solution">
                <p>First we want to thank you for your decision and putting your trust in us!</p>
                <p>Before reading, most people find their order in their spam/junk folder so please check that first.</p>
                <section>
                    <h3 className="support-answers-section-header">1. Crypto Transactions</h3>
                    <p>Crypto transactions are secure transactions. However, for the integrity of it's security, crypto transactions must be confirmed on what is known as a block chain. If you paid with crypto, the order will be marked as paid after the transaction is confirmed on the blockchain. For most crypto wallets, they will provide you with a link containing the transaction id for you to view the current confirmation status of the transaction.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">2. IceNox via Stripe (Card/ApplePay/Google Pay/etc.)</h3>
                    <p>Please make sure that the payment has actually gone through. Confirm on whatever wallet you paid with to check that the money has been withdrawn, as it is common for payments to get declined by their banks.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">3. Venmo/Zelle/Apple Cash/USA Amazon Gift Card</h3>
                    <p>These transactions must be manually confirmed by the account holder to ensure that the funds have been received in the proper manner. So contact whoever was in charge of your payment to receive the product.</p>
                </section>
                <section>
                    <h3 className="support-answers-section-header">Still Need Help?</h3>
                    <p>Contact our support through email or discord.</p>
                </section>
            </article>
        </>
    )
}